import React from 'react'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import ButtonLink from './ButtonLink'

interface Props {
  user?: {
    slug: string
    avatar?: string | null
    displayName: string
  } | null
  size?: 'default' | 'big'
  reverse?: boolean
}

const MARGINS = {
  big: 10,
  default: 5,
}

const useStyles = makeStyles(() => ({
  // ui
  root: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'inline-flex',
    alignItems: 'center',
  },
  avatar: {
    transition: 'all .5s',
    '$root:hover &': {
      borderRadius: 0,
    },
  },
  // modifiers
  reverse: {
    flexDirection: 'row-reverse',
    '&$big $avatar': {
      marginLeft: MARGINS.big,
    },
    '&$default $avatar': {
      marginLeft: MARGINS.default,
    },
  },
  default: {
    '& $avatar': {
      marginRight: MARGINS.default,
      width: 20,
      height: 20,
    },
  },
  big: {
    '& $avatar': {
      marginRight: MARGINS.big,
      width: 42,
      height: 42,
    },
  },
}))

const LinkToUserWithAvatar = ({ user, size = 'default', reverse }: Props) => {
  const classes = useStyles()
  return (
    <MuiLink
      href={`/profil?slug=${user?.slug}`}
      as={`/profil/${user?.slug}`}
      passHref
      component={ButtonLink}
      className={clsx(classes.root, {
        [classes[size]]: size,
        [classes.reverse]: reverse,
      })}
    >
      <Avatar src={user?.avatar!} className={classes.avatar} />
      <Box>
        <Typography>{user?.displayName}</Typography>
      </Box>
    </MuiLink>
  )
}

export default LinkToUserWithAvatar

import gql from 'graphql-tag'

export default gql`
  query getPostForPlaylist($objectId: ID!) {
    post(id: $objectId) {
      id
      name
      duration
      mediaUrl
      html
      postType
      thumbnail
    }
  }
`

import React from 'react'
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import MarqueeBase from 'react-marquee'

const styles = (theme: any) =>
  createStyles({
    root: {
      color: 'inherit',
      '& > span': {
        ...theme.typography.body1,
        color: 'inherit',
      },
    },
  })
interface Props extends WithStyles<typeof styles> {
  className?: string
  text: React.ComponentType | string
}
const Marquee = ({ classes, className, ...props }: Props) => {
  return <MarqueeBase className={clsx(classes.root, className)} {...props} />
}

export default withStyles(styles)(Marquee)

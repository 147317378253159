import React from 'react'
import { Query } from '@apollo/react-components'
import { getPostForPlaylist, getPostForPlaylistVariables } from '~/queries.d.ts'
import getPostQuery from '~/queries/post'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import Marquee from '~/components/Marquee'
import formatDuration from 'format-duration'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { play } from '~/ducks/player'
import { useSelector } from '~/reducers'

interface Props {
  posts: string[]
  onPlay: (postId: string, idx: number) => void
}

const useStyles = makeStyles(({ palette }) => ({
  item: {
    padding: 10,
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transitionDuration: '.5s',
    transitionProperty: 'background-color',
    '&:nth-child(odd)': {
      backgroundColor: 'rgba(0,0,0, .05)',
    },
    '& > *:first-child': {
      marginRight: 10,
    },
    '&:hover': {
      color: palette.primary.main,
    },
    '&$playing': {
      backgroundColor: palette.primary.light,
      color: 'white',
    },
  },
  playing: {},
  artwork: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minWidth: 60,
    minHeight: '100%',
    alignSelf: 'normal',
  },
  marquee: {
    overflowY: 'auto',
    padding: '0px 10px',
    flexGrow: 1,
  },
  duration: {
    textalign: 'right',
    padding: '0px 10px',
  },
}))

const PostsPlayList = ({ posts, onPlay }: Props) => {
  const classes = useStyles()
  const media = useSelector((state) => state.player.media)
  const dispatch = useDispatch()

  return (
    <div>
      {posts.map((postId, idx) => (
        <Query<getPostForPlaylist, getPostForPlaylistVariables>
          query={getPostQuery}
          variables={{ objectId: postId }}
          key={postId}
        >
          {({ loading, data }) => {
            if (loading) {
              return null
            }
            const isPlaying = postId === media
            return (
              <div
                className={clsx(classes.item, {
                  [classes.playing]: isPlaying,
                })}
                onClick={() => {
                  dispatch(play({ objectId: postId }))
                  onPlay(postId, idx)
                }}
              >
                {data?.post?.thumbnail && (
                  <div
                    className={classes.artwork}
                    style={{ backgroundImage: `url(${data?.post?.thumbnail})` }}
                  />
                )}
                <div className={classes.marquee}>
                  <Marquee text={`${data && data.post && data.post.name}`} />
                </div>
                <div className={classes.duration}>
                  <Typography>
                    {data &&
                      data.post &&
                      data.post.duration &&
                      formatDuration(data.post.duration * 1000)}
                  </Typography>
                </div>
              </div>
            )
          }}
        </Query>
      ))}
    </div>
  )
}

export default PostsPlayList
